import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { EmpresasUsuario } from 'src/app/Modelos/empresas_usuario';
import { VariablesService } from 'src/app/Servicios/variables.service';
import { Router } from '@angular/router';
import { ServiciosService } from 'src/app/Servicios/servicios.service';
import { MotivosFichajesProduccion } from 'src/app/Modelos/motivosFichajesProduccion';
import { Departamento } from 'src/app/Modelos/departamento';
import { Motivo } from 'src/app/tab2/lista-motivos/model/motivo';
import * as moment from 'moment';
import { FichjesServicioAMPresencia } from 'src/app/Modelos/fichajesAMpresenciaServicio';
import { ProcesosGlobalesService } from 'src/app/Servicios/procesos-globales.service';

@Component({
  selector: 'app-empresas-usuario',
  templateUrl: './empresas-usuario.component.html',
  styleUrls: ['./empresas-usuario.component.scss'],
})
export class EmpresasUsuarioComponent implements OnInit {
  public listado: EmpresasUsuario[];

  constructor(
    public servicios : ServiciosService,
    private variables: VariablesService,
    private globales : ProcesosGlobalesService,

    private router : Router,
    private navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {
    this.listado = this.navParams.data.listaEmpresas;
    console.log("listaEmpresas", this.listado, this.navParams.data.listaEmpresas)
   }

  ngOnInit() {
    
  }

  empresaSeleccionada(empresa : EmpresasUsuario) {
    this.variables.empresaSeleccionada = empresa;
    this.variables.claveEncriptacion = empresa.GUID_Registro
    console.log("empresa seleccionada",this.variables.empresaSeleccionada);
    this.popoverCtrl.dismiss();

          this.servicios.produccionActiva().subscribe(
            async next => {
              console.log("producción activa ", next);
              //next = true
              if (!next) {
                this.servicios.presentAlert("¡ Atencion !", "Sin producción", "La empresa seleccionada no tiene activados fichajes de producción")
                this.variables.loginCorrecto = false;
              } else {
                
                console.log("producción activa en else empresasseleccionada ", next);

                await this.listaTareasProduccion()
                .then(async () =>
                  await this.infoEmpleado()
                 
                  .then(async ()=> await this.globales.ultimofichaje()
                    .then(async () => this.router.navigate(['tabs'])))
                 
                );

                this.router.navigate(['tabs'])
              }
            },
            error => {
              console.log("error producción activa ", error);
        
            }
          );
    //this.router.navigate(['tabs'])

  }




  async infoEmpleado() {
    await this.servicios.obtenerInfoEmpleado().toPromise().then(empleado => {
      this.variables.empleadoSeleccionado = empleado;
      this.variables.departamentoSeleccionado = new Departamento();
      this.variables.departamentoSeleccionado.Id_Dependencia = empleado.Id_Dependencia
      this.variables.departamentoSeleccionado.NombreDependencia = empleado.NombreDependencia
      this.variables.departamentoSeleccionado.RecibeTickets = empleado.RecibeTickets
      console.log("tickets obtener infoEmpleado empleado " , empleado)
      console.log("tickets obtener infoEmpleado empleado " , this.variables.departamentoSeleccionado)
    })
    .catch(error => console.log("error obtenerInfoEmpleado()", error))
  }

  
    
  

  async listaTareasProduccion() {
    this.servicios.obtenerListaIncidenciasProduccion('').subscribe(
      (next: Motivo[]) => {
        console.log("tareas ", next);
        this.variables.motivos = next;
        this.variables.motivos = next.sort((a, b) => a.Nombre.localeCompare(b.Nombre) )
        this.variables.motivos = this.variables.motivos.concat()
        
        console.log("motivos ordenados", this.variables.motivos)


      },
      error => {
        console.log("error obtener motivos ", error);
      }
    )
  }

}
