import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ServiciosService } from '../Servicios/servicios.service';
import { Platform, PopoverController } from '@ionic/angular';
import { Device } from '@ionic-native/device';
import { Router } from '@angular/router';
import { VariablesService } from '../Servicios/variables.service';
import { EmpresasUsuario } from '../Modelos/empresas_usuario';
import { EmpresasUsuarioComponent } from './empresas-usuario/empresas-usuario.component';
import { Motivo } from '../tab2/lista-motivos/model/motivo';
import { Storage } from '@capacitor/storage';
import { FichjesServicioAMPresencia } from '../Modelos/fichajesAMpresenciaServicio';
import * as moment from 'moment';
import { Departamento } from '../Modelos/departamento';
import { Fichaje } from '../Modelos/fichaje';
import { ProcesosGlobalesService } from '../Servicios/procesos-globales.service';
import { globalConf } from '../Modelos/GlobalConf';
import { db } from 'src/db/db';







@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public formularioLogin: UntypedFormGroup;
  public listaEmpresas: EmpresasUsuario[];
  public recordarUsuario: boolean;
  public version = globalConf.app_version;



  constructor(
   // private device: Device,
    private servicios: ServiciosService,
    public platform: Platform,
    private router: Router,
    public variables: VariablesService,
    private popoverController: PopoverController,
    private globales : ProcesosGlobalesService,
    


  ) { this.listaEmpresas = [] }

  ngOnInit() {
    console.log("lista", this.listaEmpresas);
    this.crearFormulario();
    //this.servicios.presentLoading("Cargando Configuracion ...")

    //this.comprobarRecodarStorge();

  }

  async ionViewWillEnter() {
    await this.comprobarRecodarStorge();
  }

  crearFormulario() {
    this.formularioLogin = new UntypedFormGroup({
      loginUsuario: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[^@]+@[^@]+\.[a-zA-Z]{2,}$")
      ])),
      passwordUsuario: new UntypedFormControl('', Validators.required),
      recordarusuario: new UntypedFormControl()
    })
  }

  async login() {
    if (this.formularioLogin.valid == true && this.formularioLogin.dirty == true) {
      this.variables.usuario = this.formularioLogin.value.loginUsuario;
      this.variables.pwd = this.formularioLogin.value.passwordUsuario;
      console.log('login if (this.formularioLogin.valid == true && this.formularioLogin.dirty == true) --> ', this.formularioLogin.valid , ' dirty ', this.formularioLogin.dirty)
    } else {
      console.log('login else (this.formularioLogin.valid == true && this.formularioLogin.dirty == true) --> ', this.formularioLogin.valid , ' dirty ', this.formularioLogin.dirty)

      this.formularioLogin.value.loginUsuario = this.variables.usuario;
    }
    console.log("recordar usuario ", this.recordarUsuario)
    if (this.recordarUsuario) {
      console.log("store recordar usuario ", this.recordarUsuario)

      await Storage.set({
        key: 'user',
        value: this.variables.usuario
      });
      await Storage.set({
        key: 'pwd',
        value: this.variables.pwd
      });
      await Storage.set({
        key: 'recordar',
        value: this.variables.recordarUsuario.toString()
      });

    } else {

      console.log("borrar store recordar usuario ", this.recordarUsuario)
    
      await Storage.clear();
    }

    this.servicios.obtenerLoginMASListaEmpresas(this.variables.usuario, this.variables.pwd).subscribe(
      (next: EmpresasUsuario[]) => {
        this.variables.loginCorrecto = true;
        //this.router.navigate(['tabs']);
        this.listaEmpresas = [];
        next.forEach(empresa => {
          if (empresa.Tipo == 2) this.listaEmpresas.push(empresa);
        })
        console.log("están todas ? ", this.listaEmpresas)
        this.variables.empresasUsuario = this.listaEmpresas;
        if (this.listaEmpresas) {
          if (this.listaEmpresas.length == 1) {
            this.variables.empresaSeleccionada = this.listaEmpresas[0];
            this.variables.claveEncriptacion = this.variables.empresaSeleccionada.GUID_Registro
            console.log("empresa selecionada cuando es una", this.variables.empresaSeleccionada);
            this.servicios.produccionActiva().subscribe(
              async next => {
                console.log("producción activa ", next);
                next = true;
                if (!next) {
                  
                  this.servicios.presentAlert("¡ Atencion !", "Sin producción", "La empresa disponible no tiene activados fichajes de producción")
                  this.variables.loginCorrecto = false;
                } else {
                  await this.listaTareasProduccion()
                  .then(async () =>
                    await this.infoEmpleado()
                    .then(async ()=> await this.globales.ultimofichaje()
                      .then(async () => this.router.navigate(['tabs'])))
                    
                  );
                  
                
                  
                }
              },
              error => {
                console.log("error producción activa ", error);

              }
            );
          } else {
            if (this.listaEmpresas.length > 1) {
              this.seleccionarEmpresa(this.listaEmpresas)
            } else {
              this.servicios.presentAlert("¡ Atencion !", "No tienes empresas válidas", "Las empresas o este usuario no cumplen los requisitos para utilizar esta aplicación")
            }
          }
        } else {
          this.servicios.presentAlert("¡ Atencion !", "No tienes empresas válidas", "Las empresas o este usuario no cumplen los requisitos para utilizar esta aplicación")
        }


        console.log("bien ", next, this.listaEmpresas);
      },
      error => {
        this.variables.loginCorrecto = false;
        this.variables.usuario = "";
        this.variables.pwd = "";
        console.log("error ", error);
      }

    )
  }

  async seleccionarEmpresa(lista: EmpresasUsuario[]) {
    let ev;
    const popover = await this.popoverController.create({
      component: EmpresasUsuarioComponent,
      componentProps: { "listaEmpresas": lista },
      event: ev,
      translucent: false
    });
    return await popover.present();


  };


  async listaTareasProduccion() {
    this.servicios.obtenerListaIncidenciasProduccion('').subscribe(
      (next: Motivo[]) => {
        //console.log("aaaa tareas ", next);
        this.variables.motivos = next.sort((a, b) => (Number(a.Referencia)  < Number(b.Referencia)) ? -1 : 1)
        .sort((a, b) => (Number(a.Referencia)  < Number(b.Referencia)) ? -1 : 1);
        // this.variables.tareasProduccion.forEach(
        //   tarea => {
        //     if (tarea.Referencia.length > 4)
        //     tarea.Referencia = tarea.Referencia.substr(4,2);
        //   }
        // );
        console.log("aaaa motivos sort", this.variables.motivos);

        // .sort((a, b) => (Number(a.Referencia)  < Number(b.Referencia)) ? -1 : 1);
        //this.variables.tareasProduccion.sort((a, b) => (a.Referencia.localeCompare(b.Referencia) ) ? 1 : -1);
        //this.variables.tareasProduccion = this.variables.tareasProduccion.sort((a, b) => (Number(a.Referencia)  < Number(b.Referencia)) ? -1 : 1);


        //this.variables.tareaSeleccionada = next[0];
      },
      error => {
        console.log("error obtener tareas ", error);
      }
    )
  }

  //this.listaDepartamentosResponsable();

  async cambiarRecordar() {
    this.recordarUsuario = this.variables.recordarUsuario;
    //this.variables.recordarUsuario = !this.variables.recordarUsuario;
    console.log("cambiar recordar ", this.recordarUsuario ,'  --  ', this.variables.recordarUsuario);

  }

  async comprobarRecodarStorge() {
    let valueUsr, valuePwd, valueRecordar = null;

    valueRecordar = await Storage.get({ key: 'recordar' });
    if (valueRecordar.value == 'true') {
      //this.formularioLogin.value.recordarusuario = true;
      this.variables.recordarUsuario = true;
      console.log('Got item recordar: ', valueRecordar.value, ' variables ', this.variables.recordarUsuario);

      valueUsr = await Storage.get({ key: 'user' });
      if (valueUsr) {
        console.log("valueUsr.value", valueUsr.value , " val ", this.formularioLogin.value.loginUsuario)
        // if (valueUsr.value != this.formularioLogin.value.loginUsuario) {
        //   db.deleteDatabase();
        // }
        //this.formularioLogin.value.loginUsuario = valueUsr.value;
        this.variables.usuario = valueUsr.value
        console.log('Got item: usuario ', valueUsr.value, " variables ", this.formularioLogin.value.loginUsuario);
      }

      valuePwd = await Storage.get({ key: 'pwd' });
      if (valuePwd) {
        //this.formularioLogin.value.passwordUsuario = valuePwd;
        this.variables.pwd = valuePwd.value;
        console.log('Got item: password', valuePwd.value , ' variables ', this.variables.pwd)
      };

      this.recordarUsuario = true;
    } else {
      this.variables.recordarUsuario = false;
      this.variables.usuario = '';
      this.variables.pwd = '';
      this.recordarUsuario = false;
      db.deleteDatabase();
      //console.log("valueRecordar.value == 'false'", valuePwd.value , ' variables ', this.variables.pwd)

    }
    console.log('Got item recordar final : ', valueRecordar.value, ' variables ', this.variables.recordarUsuario);
  }



  async infoEmpleado() {
    await this.servicios.obtenerInfoEmpleado().toPromise().then(empleado => {
      this.variables.empleadoSeleccionado = empleado;
      this.variables.departamentoSeleccionado = new Departamento();
      this.variables.departamentoSeleccionado.Id_Dependencia = empleado.Id_Dependencia
      this.variables.departamentoSeleccionado.NombreDependencia = empleado.NombreDependencia
      this.variables.departamentoSeleccionado.RecibeTickets = empleado.RecibeTickets
      console.log("login obtener infoEmpleado empleado " , empleado)
      console.log("login obtener infoEmpleado empleado " , this.variables.departamentoSeleccionado)
    })
    .catch(error => console.log("error obtenerInfoEmpleado()", error))
  }

}
