import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';

export interface ProyectoDB{
    id?:number
    proyectos:string
}
export interface ClienteDB{
    id?:number
    clientes:string
}
export interface ObraDB{
    id?:number
    obras:string
}
export interface TicketDB{
    id?:number
    tickets:string
}
export interface TodosTicketDB{
    id?:number
    todostickets:string
}
export interface MotivoDB {
    id?:number
    motivos:string
}
export interface TareaDB {
    id?:number
    tareas:string
}
export interface EstadoTareaDB {
    id?:number
    estadostareas:string
}
export interface EstadoTicketDB {
    id?:number
    estadostickets:string
}
export interface Eventos {
    id?:number
    eventos:string
}

@Injectable({
    providedIn: 'root'
  })
export class AppDB extends Dexie {
    dbTickets!: Table<TicketDB>
    dbTodosTickets! : Table<TodosTicketDB>
    dbClientes!: Table<ClienteDB>
    dbObras!:Table<ObraDB>
    dbProyectos! : Table<ProyectoDB>
    dbMotivos! : Table<MotivoDB>
    dbTareas! :Table<TareaDB>
    dbEstadosTareas! : Table<EstadoTareaDB>
    dbEstadosTickets: Table<EstadoTicketDB>
    dbEventos!: Table<Eventos>
    ;

    constructor() {
        super('ngdexieliveQuery');
        this.version(2).stores({
            dbTickets:'id++',
            dbTodosTickets: 'id++',
            dbClientes:'id++',
            dbObras:'id++',
            dbProyectos: 'id++',
            dbMotivos: 'id++',
            dbTareas : 'id++',
            dbEstadosTareas : 'id++',
            dbEstadosTickets : 'id++',
            dbEventos : 'id++',
        })
    }

    async resetDatabase() {
        await db.transaction('rw', 'dbTickets', () => {
            this.dbTickets.clear();
        });
        await db.transaction('rw', 'dbTodosTickets', () => {
            this.dbTodosTickets.clear();
        });
        await db.transaction('rw', 'dbClientes', () => {
            this.dbClientes.clear();
        });
        await db.transaction('rw', 'dbObras', () => {
            this.dbObras.clear();
        });
        await db.transaction('rw', 'dbProyectos', () => {
            this.dbProyectos.clear();
        });
        await db.transaction('rw' , 'dbMotivos' , () => {
            this.dbMotivos.clear();
        });
        await db.transaction('rw' , 'dbTareas' , () => {
            this.dbTareas.clear();
        });
        await db.transaction('rw' , 'dbEstadosTareas' , () => {
            this.dbEstadosTareas.clear();
        });
        await db.transaction('rw' , 'dbEstadosTickets' , () => {
            this.dbEstadosTickets.clear();
        });
        await db.transaction('rw' , 'dbEventos' , () => {
            this.dbEventos.clear();
        });
    }

    async deleteDatabase() {
        await db.delete()
    }
}

export const db = new AppDB();