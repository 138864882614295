import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { ListaDepartamentosComponent } from '../tab2/lista-departamentos/lista-departamentos.component';
import { LogeadoGuard } from '../Servicios/logeado.guard';

const routes: Routes = [
  {

    path: 'tab1',
    loadChildren: () =>
      import('../tab1/tab1.module').then(m => m.Tab1PageModule),
      canActivate: [LogeadoGuard]
  },
  {
    path: 'tab2',
    
        loadChildren: () =>
          import('../tab2/tab2.module').then(m => m.Tab2PageModule),
          canActivate: [LogeadoGuard]
  },
  {
    path: 'tab3',
        loadChildren: () =>
          import('../tab3/tab3.module').then(m => m.Tab3PageModule),
          canActivate: [LogeadoGuard]
  },
  {
    path: '',
    redirectTo: '/tabs/tab2',
    pathMatch: 'full'
  },

];
  // {
  //   path: '',
  //   redirectTo: '/login',
  //   pathMatch: 'full'
  // }

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class TabsPageRoutingModule { }
