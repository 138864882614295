import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { globalConf } from "src/app/Modelos/GlobalConf";
import { VariablesService } from "src/app/Servicios/variables.service";
import { Observable } from "rxjs";
import { RespuestaV2 } from "../model/respuestaV2";
import { db } from 'src/db/db';

@Injectable({
    providedIn: 'root'
  })
  export class ClienteService {
  
    constructor(
      private loadingCtrl: LoadingController,
      private alertController: AlertController,
  
      private variables: VariablesService,
      public http: HttpClient
  
    ) { }
  





  getClientes(pagina : number, elementos : number) : Observable<RespuestaV2>{
    const parametros = new HttpParams()
      .set('pagina', pagina)
      .set('registrosPorPagina', elementos)
      .set('orden', 2)
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2_minimo + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_clientes, { headers: cabecera, params : parametros }) as Observable<RespuestaV2>
        ;
  }
  async guardarClientesDB(arrayClientesEnciptado: string) {
    db.dbClientes.clear().then(async () => {
      console.log('Limpieza de tabla clientes realizada.');
      db.dbClientes.add({
        clientes: arrayClientesEnciptado
      }).then(res => {
        console.log('Clientes almacenados en IndexedDB. ');
      })
    })
  }

}