import { VariablesService } from './variables.service';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Storage } from '@capacitor/storage';
import { HttpClient } from '@angular/common/http';


/**
 * Intercepta todas las peticiones HTTP que se hagan en la aplicación
 * @author Angel Moral<amoral@amsystem.es>
 */

@Injectable({
  providedIn: 'root'
})
export class EncriptarService {
  /** @ignore */
  keysEncriptadas: string[] = [];
  /** @ignore */
  hayDatos: boolean = false;
  //logeado:boolean = false;

  /** @ignore */
  constructor(
    public variablesGlobales: VariablesService,
    private http: HttpClient
    ) {
  }

  // get estaLogeado(): boolean{
  //   return this.logeado;
  // }

  // hacerLogOut(){
  //   this.logeado = false;
  //   this.eliminarLocalStorage();
  // }

  /**
   * Guardar key y value encriptado en storage
   * @param key Clave 
   * @param value Valor
   */
  async guardarEnStorage(key, value){
    await this.eliminarLocalStorage(key);
    await Storage.set({
      key: this.encriptar(key),
      value: this.encriptar(value)
    });
  }

  /**
   * Obtener value del storage en función de la clave pasada por parámetro
   * @param key Clave
   * @returns value desencriptado
   */
  async obtenerEnStorage(key){
    let keyEncriptada: string = '';
    if(await this.hayDatosAlmacenados()){
      for(var clave of this.keysEncriptadas ){
        if(this.desencriptar(clave) == key){
          keyEncriptada = clave;
        }
      }
      if(keyEncriptada!=''){
        const ret = await Storage.get({key: keyEncriptada});
        let value = this.desencriptar(ret.value);
        return value;
      }
    }else{
      console.log('No hay datos guardado en local storage');
    }
  }

  /**
   * Eleimar del local storage en función de la clave pasada por parametro
   * @param key Clave
   */
  async eliminarLocalStorage(key) {
    let keyBorrar: string = '';
    await Storage.keys().then( res => this.keysEncriptadas = res.keys);
    for(var clave of this.keysEncriptadas ){
      if(this.desencriptar(clave) == key){
        keyBorrar = clave;
      }
    }
    await Storage.remove({ key: keyBorrar });
    
  }

  /**
   * Obtener la lista de claves almacendas en el storage
   * @returns lista de claves que hay en storage
   */
  async getKey() {
    const { keys } = await Storage.keys();
    return keys;

  }
  /**
   * Obtener la lista de claves almacendas en el storage
   * @returns lista de claves que hay en storage
   */
   async getKeysDesencriptadas() {
    const { keys } = await Storage.keys();
    let keysDesencriptadas = []
    keys.forEach(key => {
      keysDesencriptadas.push(this.desencriptar(key))
    })
    return keysDesencriptadas;

  }

  /**
   * Encripta la variable que se pasa por parámetro
   * @param varAEncriptar Variable a encriptar
   * @returns Variable encriptada
   */
  encriptar(varAEncriptar){
    let varEncriptada = CryptoJS.AES.encrypt(varAEncriptar, this.variablesGlobales.claveEncriptacion).toString();
    return varEncriptada;
  }

  /**
   * Desncripta la variable que se pasa por parámetro
   * @param varADesencriptar Variable a desencriptar
   * @returns Variable desencriptada
   */
  desencriptar(varADesencriptar){
    let varDesencriptado = CryptoJS.AES.decrypt(varADesencriptar, this.variablesGlobales.claveEncriptacion);
    varDesencriptado = varDesencriptado.toString(CryptoJS.enc.Utf8);
    return varDesencriptado;
  }

  /**
   * Comprobar si ha datos almacenados en storage
   * @returns si hay o no valores en storage
   */
  async hayDatosAlmacenados(){
    await Storage.keys().then( res => this.keysEncriptadas = res.keys);
    if(this.keysEncriptadas.length>0){
      this.hayDatos = true;
      return true;
    }else{
      this.hayDatos = false;
      return false;
    }
  }

  desencriptarAES(valorEncriptado: string, key: string) {
    // ENCRIPTA LA KEY A UTF8 Y LA IV
    key = CryptoJS.enc.Utf8.parse(key);
    let iv = CryptoJS.enc.Utf8.parse(key);
    let decrypted = CryptoJS.AES.decrypt(valorEncriptado, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // SE CODIFICA A BASE64
    decrypted = decrypted.toString(CryptoJS.enc.Base64)

    // SE PASA A ARRAY DE BYTES Y SE ELIMINAN LOS 16 PRIMEROS BYTES
    let arrayBytes = base64ToByteArray(decrypted);
    let array: Uint8Array = arrayBytes[0];
    let arrayReducido = array.slice(16, array.length)

    // SE DESENCRIPTA EL ARRAY DE BYTES A UTF8
    let valorDesencriptado = stringFromUTF8Array(arrayReducido);
    console.log('Valor desencriptado: ',valorDesencriptado);
    
    return valorDesencriptado;
    function stringFromUTF8Array(data) {
      const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0];
      var count = data.length;
      var str = "";

      for (var index = 0; index < count;) {
        var ch = data[index++];
        if (ch & 0x80) {
          var extra = extraByteMap[(ch >> 3) & 0x07];
          if (!(ch & 0x40) || !extra || ((index + extra) > count))
            return null;

          ch = ch & (0x3F >> extra);
          for (; extra > 0; extra -= 1) {
            var chx = data[index++];
            if ((chx & 0xC0) != 0x80)
              return null;

            ch = (ch << 6) | (chx & 0x3F);
          }
        }

        str += String.fromCharCode(ch);
      }

      return str;
    }

    function base64ToByteArray(base64String) {
      try {
        var sliceSize = 1024;
        var byteCharacters = atob(base64String);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          var begin = sliceIndex * sliceSize;
          var end = Math.min(begin + sliceSize, bytesLength);

          var bytes = new Array(end - begin);
          for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
      } catch (e) {
        console.log("Couldn't convert to byte array: " + e);
        return;
      }
    }

  }

  encriptarAES(valorEncriptar: string, key: string ){
    var arr = new Uint8Array(16/2)
    window.crypto.getRandomValues(arr)
    var randomStr = Array.from(arr, dec2hex).join('')
    
    var encryptedlogin = CryptoJS.AES.encrypt(randomStr+valorEncriptar, CryptoJS.enc.Utf8.parse(key),
    { iv: CryptoJS.enc.Utf8.parse(key), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    var enc = encryptedlogin;
   
    console.log('Valor encriptado: ', enc.toString());
    
    
    this.http.get('https://europe-west2-alo-suite.cloudfunctions.net/cuentasBancarias/infoCuenta',  {headers: {'iban':enc.toString(),'guid':'fc05754e-ef1a-4f51-b2e0-c9366c681f11'}}).subscribe(resInfoCuenta => {
      console.log('resInfoCuenta: ', resInfoCuenta);
    })
    this.desencriptarAES(enc.toString(), key)
    function dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    }
  }
}
