import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { globalConf } from "src/app/Modelos/GlobalConf";
import { VariablesService } from "src/app/Servicios/variables.service";
import { Observable } from "rxjs";
import { RespuestaV2 } from "../model/respuestaV2";
import { Empleado } from "src/app/Modelos/empleado";
import { Ticket } from "../model/Ticket";
import { EncriptarService } from "src/app/Servicios/encriptar.service";
import { db } from 'src/db/db';
import * as moment from "moment";


@Injectable({
    providedIn: 'root'
  })
  export class TicketsService {
  
    constructor(
      private loadingCtrl: LoadingController,
      private alertController: AlertController,
      private servicioEncriptar : EncriptarService,
      private variables: VariablesService,
      public http: HttpClient
  
    ) { }
  





getTickets() : Observable<RespuestaV2>{
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    const  parametros = new HttpParams()
      .set('idsDepartamento', this.variables.empleadoSeleccionado?.Id_Dependencia)
      .set('idsEstadoTicket' , '20060124,20060125,21151910,21151911')
      console.log("departamentos ", this.variables?.empleadoSeleccionado?.NombreDependencia)

    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tickets, { headers: cabecera, params: parametros }) as Observable<RespuestaV2>
        ;
  }

  getTodosTickets() : Observable<RespuestaV2>{
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

      const  parametros = new HttpParams()
      .set('fechaInicio', moment().subtract(12, 'months').format('YYYY-MM-DD'))
      .set('idsEstadoTicket' , '20060124,20060125,21151910,21151911')
      console.log("departamentos ", this.variables?.empleadoSeleccionado?.NombreDependencia)


    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tickets, { headers: cabecera, params : parametros }) as Observable<RespuestaV2>
        ;
  }



getTicketsTecnico() : Observable<RespuestaV2>{
  const cabecera = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

  const parametros = new HttpParams()
    .set('idsEstadoTicket' , '20060124,20060125,21151910,21151911')
    .set('idsTecnicos', this.variables.empleadoSeleccionado.Codigo)
    console.log("tecnico ", this.variables?.empleadoSeleccionado?.Nombre)

  return this.http
    .get(globalConf.url_api +
      globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tickets, { headers: cabecera, params: parametros }) as Observable<RespuestaV2>
      ;
}


async actualizarTicketsOnLine() {

  let elementosLeidos = 0
  let bloqueLeido: Ticket[] = []
  this.variables.actualizadoTickets = false
  if (this.variables.tickets.length == 0) {
    console.log("es cer0 "  ,this.variables.tickets.length)
    this.showLoading()

      await this.getTickets().toPromise().then(value => {
        this.variables.tickets = value.data
        this.variables.tickets = this.variables.tickets.concat();
        elementosLeidos = value.data?.length
        console.log("variables tickets ", this.variables?.tickets?.length)
        this.variables.actualizadoTickets = true;
        this.loadingCtrl.dismiss()
      }).catch(error => { console.log("error gettickets ", error) })


      this.variables.actualizadoTickets = true;

      let arrayTicketsEnciptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.tickets))
      console.log('he salido del while ')
      this.guardarTicketsDB(arrayTicketsEnciptado)
    } else {
      let tickets : Ticket[] = []
      //this.showLoading()
      this.getTickets().toPromise().then(value => {
        bloqueLeido = value.data
        bloqueLeido.forEach(ticket => {
          tickets.push(ticket)
        });
        //this.variables.clientes = this.variables.clientes.concat();
        elementosLeidos = value.data?.length
        this.variables.tickets = tickets
        this.variables.tickets.concat()
        //this.loadingCtrl.dismiss()
        console.log("regargando tickets ", tickets?.length)
      }).catch(error => { console.log("error recargar getTickets ", error) })
    }
  } 

  async actualizarTodosTicketsOnLine() {

    let elementosLeidos = 0
    let bloqueLeido: Ticket[] = []
    this.variables.actualizadoTickets = false
    if (this.variables.todosTickets.length == 0) {
      console.log("es cer0 "  ,this.variables.todosTickets.length)
      this.showLoading()
  
        await this.getTodosTickets().toPromise().then(value => {
          this.variables.todosTickets = value.data
          this.variables.todosTickets = this.variables.todosTickets.concat();
          elementosLeidos = value.data?.length
          console.log("variables tickets ", this.variables?.todosTickets?.length)
          this.variables.actualizadoTodosTickets = true;
          this.loadingCtrl.dismiss()
        }).catch(error => { console.log("error gettickets ", error) })
  
  
        this.variables.actualizadoTodosTickets = true;
  
        let arrayTicketsEnciptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.todosTickets))
        console.log('he salido del while ')
        this.guardarTodosTicketsDB(arrayTicketsEnciptado)
      } else {
        let todosTickets : Ticket[] = []
        //this.showLoading()
        this.getTodosTickets().toPromise().then(value => {
          bloqueLeido = value.data
          bloqueLeido.forEach(ticket => {
            todosTickets.push(ticket)
          });
          //this.variables.clientes = this.variables.clientes.concat();
          elementosLeidos = value.data?.length
          this.variables.todosTickets = todosTickets
          this.variables.todosTickets.concat()
          //this.loadingCtrl.dismiss()
          console.log("regargando tickets ", todosTickets?.length)
        }).catch(error => { console.log("error recargar getTickets ", error) })
      }
    } 


  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando tickets',
    });

    loading.present();
  }

  async guardarTicketsDB(arrayTicketsEnciptado: string) {
    db.dbTickets.clear().then(async () => {
      console.log('Limpieza de tabla tickets realizada.');
      db.dbTickets.add({
        tickets: arrayTicketsEnciptado
      }).then(res => {
        console.log('Tickets almacenados en IndexedDB. ');
      })

    })
  }

  async guardarTodosTicketsDB(arrayTicketsEnciptado: string) {
    db.dbTodosTickets.clear().then(async () => {
      console.log('Limpieza de tabla TodosTickets realizada.');
      db.dbTodosTickets.add({
        todostickets: arrayTicketsEnciptado
      }).then(res => {
        console.log('TodosTickets almacenados en IndexedDB. ');
      })

    })
  }

  cambiarEstadoTicket(estado : number) : Observable<any> { 
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    var temp : object[]= [];
    temp.push({  
    "idTicket": this.variables.ticketSeleccionado.id,
    "idEstado": estado,
    "descripcion": "Estado modificado desde control de producción",
    "fecha": moment().format('YYYY-MM-DD HH:mm:ss')
    }) 

    const body = JSON.stringify(temp);
    console.log("cambiar estado ticket ", body);
    return this.http
      .post(globalConf.url_api +
        globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_cambiosEstadosTickets, body, { headers: cabecera }) as Observable<any>
      ;
  }
}