import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { Device } from '@ionic-native/device/ngx';

import { IonicModule } from '@ionic/angular';

import { LoginPageRoutingModule } from './login-routing.module';

import { LoginPage } from './login.page';
import { TabsPageRoutingModule } from '../tabs/tabs-routing.module';
import { EmpresasUsuarioComponent } from './empresas-usuario/empresas-usuario.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    LoginPageRoutingModule,
    TabsPageRoutingModule
  ],
  providers: [
    Device,
  ],
  declarations: [LoginPage, EmpresasUsuarioComponent]
})
export class LoginPageModule {}
