import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import  'hammerjs';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen, SplashScreenOriginal } from '@ionic-native/splash-screen';
//import { StatusBar, StatusBarOriginal } from '@ionic-native/status-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageModule } from '../app/login/login.module';
import { HttpClientXsrfModule,HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientXsrfModule,
        HttpClientModule,
        LoginPageModule,
        BrowserModule,
        HammerModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
