import { Component, OnInit } from '@angular/core';
import { VariablesService } from '../Servicios/variables.service';
import { Router } from '@angular/router';
import { ServiciosService } from '../Servicios/servicios.service';
import { ParametrosPresencia } from '../Modelos/parametrosPresencia';
import { EstadosTareasService } from '../tab2/lista-estadosTareas/service/EstadosTareasService';
import { AppDB } from 'src/db/db';
import { TareasService } from '../tab2/lista-tareas/service/TareasService';
import { TicketsService } from '../tab2/lista-tikets/service/TicketsService';
import { ProyectosService } from '../tab2/lista-proyectos/service/ProyectosService';
import { ObrasService } from '../tab2/lista-obras/service/ObrasService';
import { EncriptarService } from '../Servicios/encriptar.service';
import { ProcesosGlobalesService } from '../Servicios/procesos-globales.service';
import { ClienteService } from '../tab2/lista-clientes/service/ClientesService';



@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit {
  parametros: ParametrosPresencia[];


  constructor(
    public servicio: ServiciosService,
    public variables: VariablesService,
    public router: Router,
    public estadosTareasService :EstadosTareasService,
    public tareasService :TareasService,
    private ticketService: TicketsService,
    private proyectosService : ProyectosService,
    private obrasService : ObrasService,
    private servicioEncriptar : EncriptarService,
    private procesosGlobales : ProcesosGlobalesService,
    private clientesService : ClienteService,


    public db : AppDB
  ) { }
  ngOnInit(): void {
    this.comprobarParametroNotasDeProduccion();
    //this.comprobarTicketsDepartamento();
    this.comprobarTicketsTecnico();
    if (this.variables.estadosTareas.length == 0) this.cargarEstados() 

    if (this.variables.tareas.length == 0) this.cargarTareas()
    else this.procesosGlobales.cargarTareasDB();
    if (this.variables.tickets.length == 0) this.cargarTickets();
    else this.procesosGlobales.cargarTicketsDB();
    if (this.variables.proyectos.length == 0) this.cagarProyectos()
    else this.procesosGlobales.cargarProyectosDB();
    if (this.variables.obras.length == 0) this.cargarObras()
    else this.procesosGlobales.cargarObrasDB();
    if (this.variables.clientes.length == 0) this.cargarClientes()
    else this.procesosGlobales.cargarClientesDB();
    if (this.variables.todosTickets.length == 0) this.cargarTodosTickets()
    else this.procesosGlobales.cargarTodosTicketsDB();
  }


  cargarTodosTickets() {
    
    this.ticketService.getTodosTickets().subscribe(
      next => {
        console.log("tickets ", next)
        this.variables.todosTickets = next.data
        let arrayTicketsEnciptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.todosTickets))
        console.log('cargado en tabs tickets')
        this.ticketService.guardarTodosTicketsDB(arrayTicketsEnciptado)
        this.procesosGlobales.cargarTodosTicketsDB()
      },
      error => {
        console.log("error al obtener tickets", error)
      }
    )
  }
  cargarClientes() {
    this.clientesService.getClientes(-953,100).subscribe(
      next => {
        console.log("clientes ", next)
        this.variables.clientes = next.data
        let arrayClientesEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.clientes))
        console.log('cargado en tabs clientes')
        this.clientesService.guardarClientesDB(arrayClientesEncriptado)
        this.procesosGlobales.cargarClientesDB()
      },
      error => {
        console.log("error al obtener clientes", error)
      }
    )
  }
  cargarObras() {
    this.obrasService.getObras().subscribe(
      next => {
        console.log("obras ", next)
        this.variables.obras = next.data
        let arrayObrasEnciptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.obras))
        console.log('cargado en tabs obras')
        this.obrasService.guardarObrasDB(arrayObrasEnciptado)
        this.procesosGlobales.cargarObrasDB()
        
      },
      error => {
        console.log("error al obtener obras", error)
      }
    )
  }
  cagarProyectos() {
    this.proyectosService.getProyectos().subscribe(
      next => {
        console.log("proyectos ", next)
        this.variables.proyectos = next.data
        let arrayProyectosEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.proyectos))
        console.log('cargado en tabs proyectos')
        this.proyectosService.guardarProyectosDB(arrayProyectosEncriptado)
        this.procesosGlobales.cargarProyectosDB()
      },
      error => {
        console.log("error al obtener proyectos", error)
      }
    )
  }
  cargarTickets() {
    this.ticketService.getTickets().subscribe(
      next => {
        console.log("tickets ", next)
        this.variables.tickets = next.data
        let arrayTicketsEnciptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.tickets))
        console.log('cargado en tabs tickets')
        this.ticketService.guardarTicketsDB(arrayTicketsEnciptado)
        this.procesosGlobales.cargarTicketsDB()
      },
      error => {
        console.log("error al obtener tickets", error)
      }
    )
  }
  cargarTareas() {
    this.tareasService.getTareas(-953, 100).subscribe(
      next => {
        this.variables.tareas = next.data
        let arrayTareasEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.tareas))
        this.tareasService.guardarTareasDB(arrayTareasEncriptado) 
        this.procesosGlobales.cargarTareasDB()
      },
      error => {
        console.log("error al obtener tareas", error)
      },
      () => {
        this.tareasService.getEventos(-953, 100).subscribe(
          next => {
            this.variables.tareas = this.variables.tareas.concat(next.data) 
            this.variables.tareas = this.variables.tareas.concat()
            let arrayTareasEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.tareas))
            console.log('cargado en tabs tareas 1', this.variables.tareas.length , 'array' , this.variables.tareas)
            this.tareasService.guardarTareasDB(arrayTareasEncriptado).then(res => {
            this.procesosGlobales.cargarTareasDB().then(res => {
            console.log('cargado en tabs tareas 2', this.variables.tareas.length , 'array' , this.variables.tareas)
            })
            })
          },
          error => {
            console.log("error al obtener tareas", error)
          });

      }
    )
    







  }
  cargarEstados() {
    this.estadosTareasService.obtenerEstadosTareas();
    this.estadosTareasService.obtenerEstadosTickets();
  }

  // comprobarTicketsDepartamento() {
  //   this.servicio.getTicketsDepartamentos().subscribe(next => {
  //     if (next.data.length > 0) this.variables.departamentoConTickets = true;
  //     else this.variables.departamentoConTickets = false;

  //     console.log("correcto obtener tickets departamentos", next)
  //   }, 
  //   error => {
  //     console.log("error al obtener tickets departamentos",error)
  //   })
  // }
  comprobarTicketsTecnico() {
    this.servicio.getTicketsTecnicos().subscribe(next => {
      if (next.data.length > 0) this.variables.tecnicoConTickets = true;
      else this.variables.tecnicoConTickets = false;

      console.log("correcto obtener tickets tecnico", next)
    }, 
    error => {
      console.log("error al obtener tickets tecnico",error)
    })
  }


  comprobarParametroNotasDeProduccion() {
    this.variables.tieneNotasProduccion = false;
    this.servicio.obtenerParametrosPresencia().subscribe(
      next => {
        next.forEach((parametro: ParametrosPresencia) => {
          if (parametro.Codigo == "InsertarNotaFichajeProduccion") {
            this.variables.tieneNotasProduccion = (parametro.Valor === "true")
          }
        })
        console.log("notas fichajes prod ", this.variables.tieneNotasProduccion ? true : false)
      });
  }

  longOff() {
    this.variables.loginCorrecto = false;
    this.variables.empresasUsuario = [];
    this.variables.empresaSeleccionada = null;
    this.variables.empleadoSeleccionado = null;
    this.variables.ultimoFichaje = null;
    this.variables.cierrePendiente = null;
    this.variables.empleadosDepartamento = null;
    this.variables.motivoFichajeSeleccionado = null;
    this.variables.motivosFichejeProduccion = null;
    this.variables.proyectoSeleccionado = null;
    this.variables.ticketSeleccionado = null;
    this.variables.obraSeleccionada = null;
    this.variables.clienteSeleccionado = null;
    this.variables.tareaSeleccionada = null;
    // this.variables.clientes = [];
    // this.variables.obras = [];
    // this.variables.proyectos = [];
    // this.variables.tickets = [];
    // this.variables.tareas = [];
    // this.variables.todosTickets = [];
    // this.variables.estadosTareas = [];
    // this.variables.estadosTickets = [];
      
    
    
    
    // if (!this.variables.recordarUsuario) {
    //   this.variables.usuario = '';
    //   this.variables.pwd = '';
    //   this.variables.recordarUsuario = false;
    // }

    console.log("Mensaje ", this.variables.loginCorrecto)
    this.router.navigate([''])
  }

}
