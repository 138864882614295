import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { globalConf } from "src/app/Modelos/GlobalConf";
import { VariablesService } from "src/app/Servicios/variables.service";
import { Observable } from "rxjs";
import { EstadoTarea } from "../model/estadotarea";
import { RespuestaV2 } from "../model/respuestaV2";
import { EncriptarService } from "src/app/Servicios/encriptar.service";
import { db } from 'src/db/db';
import { RespuestaV2Tickets } from "../model/respuestaV2_EstadosTickets";

@Injectable({
    providedIn: 'root'
  })
  export class EstadosTareasService {
    constructor(
      private variables: VariablesService,
      public http: HttpClient,
      public servicioEncriptar: EncriptarService,
    ) { }
  
 getEstadosTareas() : Observable<RespuestaV2>{

    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api +
        globalConf.path_EstadosTareas , { headers: cabecera }) as Observable<RespuestaV2>
        ;
  }

  getEstadosTickets() : Observable<RespuestaV2Tickets>{

    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api + 
        globalConf.path_v2_crm +  this.variables.empresaSeleccionada.GUID_Registro +  globalConf.path_estados_tickets , { headers: cabecera }) as Observable<RespuestaV2Tickets>
        ;
  }

  obtenerEstadosTareas() {

  this.getEstadosTareas().subscribe(
    next => {
      this.variables.estadosTareas = next.data
      if (this.variables.estadosTareas.length == 0) this.variables.ocultarEstadosTareas = true;
      this.variables.estadosTareas = next.data.sort((a, b) => a.key.localeCompare(b.key) )
      this.variables.estadosTareas = this.variables.estadosTareas.concat()
      let arrayMotivosEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.estadosTareas))
      this.guardarEstadosTareasDB(arrayMotivosEncriptado)
      console.log('leidos estadosTareas ', next.data)
    },
    error => {
      console.log("gamba en estadosTareas", this.variables.estadosTareas)
    });
  }

  obtenerEstadosTickets() {

    this.getEstadosTickets().subscribe(
      next => {
        this.variables.estadosTickets = next.data
        if (this.variables.estadosTickets.length == 0) {
          this.variables.ocultarEstadosTickets = true;
        } else {
        this.variables.estadosTickets = next?.data.sort((a, b) => a.nombre.localeCompare(b.nombre) )
        this.variables.estadosTickets = this.variables.estadosTickets.concat()
        let arrayMotivosEncriptado = this.servicioEncriptar.encriptar(JSON.stringify(this.variables.estadosTickets))
        this.guardarEstadosTicketsDB(arrayMotivosEncriptado)
        console.log('leidos estadosTickets ', next.data)
        }
      },
      error => {
        console.log("gamba en estadosTickets", this.variables.estadosTickets)
      });
    }


  



seleccionarEstadoTarea(item: EstadoTarea) {
  console.log("selecionado estadoTarea :", item.key)
  this.variables.estadoTareaSeleccionado = item;
  console.log("estadoTarea seleccionado:", this.variables.estadoTareaSeleccionado)
 // this.modalCtrl.dismiss()
}

detecta(event: Event) {
  //console.log("evento scroll" ,event, "  n  " );
}

handleInput(event: CustomEvent) {
  console.log("texto a buscar ", event.detail.value)
 // this.textoBuscar = event.detail.value
}

async guardarEstadosTareasDB(arrayEnciptado: string) {
  db.dbEstadosTareas.clear().then(async () => {
    console.log('Limpieza de tabla estadosTareas realizada.');
    db.dbEstadosTareas.add({
      estadostareas: arrayEnciptado
    }).then(res => {
      console.log('Estados tareas produccion almacenados en IndexedDB. ', res);
    })
  })
}

guardarEstadosTicketsDB(arrayEncriptado: any) {
  db.dbEstadosTickets.clear().then(async () => {
    console.log('Limpieza de tabla estadosTickets realizada.');
    db.dbEstadosTickets.add({
      estadostickets: arrayEncriptado
    }).then(res => {
      console.log('Estados tareas produccion almacenados en IndexedDB. ', res);
    })
  })
}

}

