import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiciosService } from './servicios.service';
import { VariablesService } from './variables.service';


@Injectable({
  providedIn: 'root'
})
export class LogeadoGuard  {

  constructor(
    private variables : VariablesService,
    private servicio : ServiciosService,
    private router : Router
  ) {};
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.variables.loginCorrecto) { // method in auth service to determine user status
      console.log("Si puede volver al list");
      
      return true; // desired page 'cannot activate'
    } else {
      console.log("no te dejo ir a la  list");
      this.servicio.presentAlert("Alerta",  'No está logeado', 'Para acceder es neceraio tener credenciales válidas y ser responsable de departamento')
      
      return false; // desired page 'can activate' if user is logged
    }
  }

}
