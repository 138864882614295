import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { globalConf } from "src/app/Modelos/GlobalConf";
import { VariablesService } from "src/app/Servicios/variables.service";
import { Observable } from "rxjs";
import { RespuestaV2 } from "../model/respuestaV2";
import { Empleado } from "src/app/Modelos/empleado";
import * as moment from "moment";
import { db } from 'src/db/db';

@Injectable({
    providedIn: 'root'
  })
  export class TareasService {
  
    constructor(
      private loadingCtrl: LoadingController,
      private alertController: AlertController,
  
      private variables: VariablesService,
      public http: HttpClient
  
    ) { }
  

      getTareas(pagina : number, elementos : number) : Observable<RespuestaV2>{
        const parametros = new HttpParams()
          .set('pagina', pagina)
          .set('registrosPorPagina', elementos)
          .set('orden', 3)
          .set('idsEmpleados', this.variables.empleadoSeleccionado.Codigo)
          .set('idsEstadoTarea',  '0,1,2,4')
        const cabecera = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

        return this.http
          .get(globalConf.url_api +
            globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tareas, { headers: cabecera, params : parametros }) as Observable<RespuestaV2>
            ;
      }

      getTodosTickets(pagina:number, elemento:number) {

      }

      getEventos(pagina:number, elemento:number) : Observable<RespuestaV2> {
        const parametros = new HttpParams()
        .set('pagina', pagina)
        .set('registrosPorPagina', elemento)
        .set('orden', 3)
        .set('idsEmpleados', this.variables.empleadoSeleccionado.Codigo)
        .set('idsEstadoEvento',  '0,1,2,4')
      const cabecera = new HttpHeaders() 
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

      return this.http
        .get(globalConf.url_api +
          globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_eventos, { headers: cabecera, params : parametros }) as Observable<RespuestaV2>
          ;
      }

      cambiarEstadoTareas(estado : number) : Observable<any> { 

        const cabecera = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);
        var temp  : object[] = []
        temp.push({
          "id": this.variables.tareaSeleccionada.id,
          "estado": estado
        })
        const body = JSON.stringify(temp)

        console.log("cambiar estado tarea ", body , " estado " ,estado);
        return this.http
          .put(globalConf.url_api +
            globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_cambiosEstadosTareas, body, { headers: cabecera }) as Observable<any>
          ;
      }
      async guardarTareasDB(arrayTareasEnciptado: string) {
        //console.log('Guardando tareas en IndexedDB. ', arrayTareasEnciptado);
        db.dbTareas.clear().then(async () => {
          console.log('Limpieza de tabla tareas realizada.');
          db.dbTareas.add({
            tareas: arrayTareasEnciptado
          }).then(res => {
            console.log('Tareas almacenados en IndexedDB. ');
          });
    
        })
      }
      guardarEventosDB(arrayEventossEncriptado: any) {
        db.dbEventos.clear().then(async () => {
          console.log('Limpieza de tabla eventos realizada.');
          db.dbEventos.add({
            eventos: arrayEventossEncriptado
          }).then(res => {
            console.log('Eventos almacenados en IndexedDB. ');
          });
    
        })
      }
  }