import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { globalConf } from "src/app/Modelos/GlobalConf";
import { VariablesService } from "src/app/Servicios/variables.service";
import { Proyecto } from "../model/Proyecto";
import { Observable } from "rxjs";
import { RespuestaV2 } from "../model/respuestaV2";
import { db } from 'src/db/db';

@Injectable({
    providedIn: 'root'
  })
  export class ProyectosService {
  
    constructor(
      private loadingCtrl: LoadingController,
      private alertController: AlertController,
  
      private variables: VariablesService,
      public http: HttpClient
  
    ) { }
  





getProyectos() : Observable<RespuestaV2>{
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2 + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_proyectosv2, { headers: cabecera }) as Observable<RespuestaV2>
        ;
  }

  async guardarProyectosDB(arrayEnciptado: string) {
    db.dbProyectos.clear().then(async () => {
      console.log('Limpieza de tabla proyectos realizada.');
      db.dbProyectos.add({
        proyectos: arrayEnciptado
      }).then(res => {
        console.log('Proyectos almacenados en IndexedDB. ');
      })

    })
  }

}