import { Injectable } from '@angular/core';
import { EncriptarService } from './encriptar.service';
import { HttpClient } from '@angular/common/http';
import { Parametro } from '../Modelos/Parametro';
import { globalConf } from '../Modelos/GlobalConf';
import { db } from 'src/db/db';
import { VariablesService } from './variables.service';
import { ClienteMinimo } from '../tab2/lista-clientes/model/ClienteMinimo';
import { Ticket } from '../tab2/lista-tikets/model/Ticket';
import { Obra } from '../tab2/lista-obras/model/Obra';
import { ServiciosService } from './servicios.service';
import * as moment from 'moment';
import { FichjesServicioAMPresencia } from '../Modelos/fichajesAMpresenciaServicio';
import { Tarea } from '../tab2/lista-tikets/model/Tarea';
import { FichajeProduccion } from '../Modelos/fichajeProduccion';

@Injectable({
  providedIn: 'root'
})
export class ProcesosGlobalesService {
  cerrarTarea: any;

  constructor(
    public variables: VariablesService,
    private servicioEncriptar: EncriptarService,
    private servicios: ServiciosService,
    private http: HttpClient
  ) { }

  async procesoPreCargaDatosStorage() {
    this.cargarObrasDB()
    this.cargarTicketsDB()
    this.cargarTodosTicketsDB()
    this.cargarClientesDB()
    this.cargarProyectosDB()
    this.cargarMotivosDB()
    this.cargarTareasDB()
    this.cargarEventosDB()

  }
  cargarEventosDB() {
    db.dbEventos?.toArray().then(eventosDB => {
      let arrayEventosDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(eventosDB[0].eventos))
      this.variables.eventos = arrayEventosDesencriptados
      this.variables.cargadosEventos = true;
      console.log('Cargados eventos de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "eventos" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos eventos almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbEventos.clear().then(() => console.log("eventos borrado de almacenamiento"))
      }
      this.variables.cargadosEventos = true;
    })
  }
  async cargarTareasDB(): Promise<Tarea[]> {
    db.dbTareas?.toArray().then(tareasDB => {
      let arrayTareasDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(tareasDB[0].tareas))
      this.variables.tareas = arrayTareasDesencriptados
      this.variables.cargadasTareas = true;
      if(this.variables.tareas.length = 0) this.variables.ocultarTareas = true

      console.log('Cargadas Tareas de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "tareas" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos tareas almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbTareas.clear().then(() => console.log("tareas borrado de almacenamiento"))
      }
      this.variables.cargadasTareas = true;
    })
    return this.variables.tareas
  }



  async cargarObrasDB(): Promise<Obra[]> {
    db.dbObras?.toArray().then(obrasDB => {
      let arrayObrasDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(obrasDB[0].obras))
      this.variables.obras = arrayObrasDesencriptados
      if(this.variables.obras.length = 0) this.variables.ocultarObras = true
      this.variables.cargadasObras = true;
      console.log('Cargados obras de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "obras" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos obras almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbObras.clear().then(() => console.log("obras borrado de almacenamiento"))
      }
      this.variables.cargadasObras = true;
    })
    return this.variables.obras
  }



  async cargarTicketsDB(): Promise<Ticket[]> {
    db.dbTickets?.toArray().then(ticketsDB => {
      let arrayTicketsDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(ticketsDB[0].tickets))
      this.variables.tickets = arrayTicketsDesencriptados
      this.variables.cargadosTickets = true;
      if(this.variables.tickets.length == 0) this.variables.ocultarTickets = false


      console.log('Cargados Tickets de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "tickets" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos tickets almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbTickets.clear().then(() => console.log("tickets borrado de almacenamiento"))
      }
      this.variables.cargadosTickets = true;
    })
    return this.variables.tickets
  }

  async cargarTodosTicketsDB(): Promise<Ticket[]> {
    db.dbTodosTickets?.toArray().then(todosticketsDB => {
      let arrayTicketsDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(todosticketsDB[0].todostickets))
      this.variables.todosTickets = arrayTicketsDesencriptados
      this.variables.cargadosTodosTickets = true;

      console.log('Cargados TodosTickets de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "Todostickets" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos todostickets almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbTickets.clear().then(() => console.log("todostickets borrado de almacenamiento"))
      }
      this.variables.cargadosTodosTickets = true;
    })
    return this.variables.todosTickets
  }

  async cargarClientesDB(): Promise<ClienteMinimo[]> {
    db.dbClientes?.toArray().then(clientesDB => {
      let arrayClientesDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(clientesDB[0].clientes))
      this.variables.clientes = arrayClientesDesencriptados
      this.variables.cargadosClientes = true;
      console.log('Cargados clientes de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "clientes" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos clientes almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbClientes.clear().then(() => console.log("clientes borrado de almacenamiento"))
      }
      this.variables.cargadosClientes = true;
    })
    return this.variables.clientes
  }

  async cargarProyectosDB() {
    db.dbProyectos?.toArray().then(proyectosDB => {
      let arrayProyectosDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(proyectosDB[0].proyectos))
      this.variables.proyectos = arrayProyectosDesencriptados
      this.variables.cargadosProyectos = true;
      if(this.variables.proyectos.length = 0) this.variables.ocultarProyectos = true


      console.log('Cargados proyectos de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "clientes" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos proyectos almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbProyectos.clear().then(() => console.log("proyectos borrado de almacenamiento"))
      }
      this.variables.cargadosProyectos = true;
    })
    return this.variables.proyectos
  }

  async cargarMotivosDB() {
    db.dbMotivos?.toArray().then(motivosDB => {
      let arrayMotivosDesencriptados = JSON.parse(this.servicioEncriptar.desencriptar(motivosDB[0].motivos))
      this.variables.motivos = arrayMotivosDesencriptados
      this.variables.cargadosMotivos = true;

      console.log('Cargados motivos de indexedDB');
    }).catch((error: string) => {
      error = error.toString()
      console.log('No se ha encontrado la tabla "motivos" en IndexDB.', error)
      if (error.includes('TypeError')) console.log("no hay datos motivos almacenados")
      if (error.includes('Error: Malformed UTF-8 data')) {
        console.log("error clave encriptacion se elimina almacenamiento")
        db.dbMotivos.clear().then(() => console.log("motivos borrado de almacenamiento"))
      }
      this.variables.cargadosMotivos = true;
    })
    return this.variables.motivos
  }

  async ultimofichaje() {
    this.servicios.obtenerUltimoFichaje(moment().format().substr(0, 10), this.variables.empleadoSeleccionado.Codigo, true, true).subscribe(
      (next: FichjesServicioAMPresencia[]) => {
        this.variables.ultimoFichajeAM = next;
        console.log("ultimo fichaje ", next)

        if (next.length > 1) {

          if ((this.variables?.ultimoFichajeAM[0]?.Salida?.Observaciones == 'Cierre automático Alo' || this.variables?.ultimoFichajeAM[0]?.Salida?.Observaciones == 'cierre auto')
            && this.variables?.ultimoFichajeAM[0]?.Salida?.Id_Cliente == this.variables?.ultimoFichajeAM[1]?.Entrada?.Id_Cliente
            && this.variables?.ultimoFichajeAM[0]?.Salida?.Id_Proyecto == this.variables?.ultimoFichajeAM[1]?.Entrada?.Id_Proyecto
            && this.variables?.ultimoFichajeAM[0]?.Salida?.Id_Ticket == this.variables?.ultimoFichajeAM[1]?.Entrada?.Id_Ticket
            && this.variables?.ultimoFichajeAM[0]?.Salida?.Id_TareaTicket == this.variables?.ultimoFichajeAM[1]?.Entrada?.Id_TareaTicket) {
            console.log("es un cierre de su tarea anterior explotado ", this.variables?.ultimoFichajeAM)
            this.variables.ultimoFichaje = next[0].Salida;
            this.variables.ultimoFichaje.isTareaCerrada = true

          } else if (!(this.variables?.ultimoFichajeAM[0]?.Salida?.Fecha && this.variables?.ultimoFichajeAM[1]?.Salida?.Fecha)) {
            console.log("NO un cierre de su tarea anterio explotado ", this.variables.ultimoFichajeAM)
            this.variables.ultimoFichaje = next[1].Entrada;
            this.variables.ultimoFichaje.isTareaCerrada = false

            this.cerrarTarea = new FichajeProduccion();

            this.cerrarTarea.codigoPersonal = this.variables?.empleadoSeleccionado?.Codigo;
            this.cerrarTarea.entradaOsalida = false;
            this.cerrarTarea.fecha = moment().subtract(1, 'minute').format();
            this.cerrarTarea.incidencia = Number(this.variables.ultimoFichaje.CodigoI);
            this.cerrarTarea.observaciones = "Cerrado Manualmente";
            this.cerrarTarea.produccion = true;
            this.cerrarTarea.produccion_Unidad1 = this.variables.ultimoFichaje.Produccion_Unidad1;
            this.cerrarTarea.produccion_referenciaOrden = this.variables.ultimoFichaje.Produccion_ReferenciaOrden
            this.cerrarTarea.nota = this.variables.ultimoFichaje.Notas;
            this.cerrarTarea.idProyecto = this.variables.ultimoFichaje.Id_Proyecto;
            this.cerrarTarea.idObra = this.variables.ultimoFichaje.Id_Obra;
            this.cerrarTarea.idTicket = this.variables.ultimoFichaje.Id_Ticket;
            this.cerrarTarea.idCliente = this.variables.ultimoFichaje.Id_Cliente;
            this.cerrarTarea.idTarea = this.variables.ultimoFichaje.Id_TareaTicket
            this.variables.cierrePendiente = this.cerrarTarea;
            console.log(" cerrar tarea cierre penciente = ", this.cerrarTarea);
          } else if (this.variables.ultimoFichajeAM[0].Salida.Fecha && this.variables.ultimoFichajeAM[1].Salida.Fecha) {
            console.log("NO un cierre de su tarea anterio explotado ", this.variables.ultimoFichajeAM)
            this.variables.ultimoFichaje = next[0].Salida;
            this.variables.ultimoFichaje.isTareaCerrada = true

            this.cerrarTarea = new FichajeProduccion();

            // this.cerrarTarea.codigoPersonal = this.variablesGlobales?.empleadoSeleccionado.Codigo;
            // this.cerrarTarea.entradaOsalida = false;
            // this.cerrarTarea.fecha = moment().subtract(1, 'minute').format();
            // this.cerrarTarea.incidencia = Number(this.variablesGlobales.ultimoFichaje.CodigoI);
            // this.cerrarTarea.observaciones = "Cerrado Manualmente";
            // this.cerrarTarea.produccion = true;
            // this.cerrarTarea.produccion_Unidad1 = this.variablesGlobales.ultimoFichaje.Produccion_Unidad1;
            // this.cerrarTarea.produccion_referenciaOrden = this.variablesGlobales.ultimoFichaje.Produccion_ReferenciaOrden
            // this.cerrarTarea.nota = this.variablesGlobales.ultimoFichaje.Notas;
            // this.cerrarTarea.idProyecto = this.variablesGlobales.ultimoFichaje.Id_Proyecto;
            // this.cerrarTarea.idObra = this.variablesGlobales.ultimoFichaje.Id_Obra;
            // this.cerrarTarea.idTicket = this.variablesGlobales.ultimoFichaje.Id_Ticket;
            // this.cerrarTarea.idCliente = this.variablesGlobales.ultimoFichaje.Id_Cliente;
            // this.cerrarTarea.id_Tarea = this.variablesGlobales.ultimoFichaje.Id_TareaTicket
            // this.variablesGlobales.cierrePendiente = this.cerrarTarea;
            // console.log(" cerrar tarea cierre penciente = ", this.cerrarTarea);
          }

        } else {
          if(next[0]?.Entrada) {
            this.variables.ultimoFichaje = next[0].Entrada;
            this.variables.ultimoFichaje.isTareaCerrada = false
            console.log("fichaje con entrada " , this.variables.ultimoFichaje);
          


          this.cerrarTarea = new FichajeProduccion();

          this.cerrarTarea.codigoPersonal = this.variables?.empleadoSeleccionado.Codigo;
          this.cerrarTarea.entradaOsalida = false;
          this.cerrarTarea.fecha = moment().subtract(1, 'minute').format();
          this.cerrarTarea.incidencia = Number(this.variables.ultimoFichaje.CodigoI);
          this.cerrarTarea.observaciones = "Cerrado Manualmente";
          this.cerrarTarea.produccion = true;
          this.cerrarTarea.produccion_Unidad1 = this.variables.ultimoFichaje.Produccion_Unidad1;
          this.cerrarTarea.produccion_referenciaOrden = this.variables.ultimoFichaje.Produccion_ReferenciaOrden
          this.cerrarTarea.nota = this.variables.ultimoFichaje.Notas;
          this.cerrarTarea.idProyecto = this.variables.ultimoFichaje.Id_Proyecto;
          this.cerrarTarea.idObra = this.variables.ultimoFichaje.Id_Obra;
          this.cerrarTarea.idTicket = this.variables.ultimoFichaje.Id_Ticket;
          this.cerrarTarea.idCliente = this.variables.ultimoFichaje.Id_Cliente;
          this.cerrarTarea.idTarea = this.variables.ultimoFichaje.Id_TareaTicket
          this.variables.cierrePendiente = this.cerrarTarea;
          console.log(" cerrar tarea cierre pendiente = ", this.cerrarTarea);
          }

        }







        // if(this.variablesGlobales?.ultimoFichajeAM[0]?.Entrada?.Fecha){ 
        //   this.variablesGlobales.ultimoFichaje = next[0].Entrada;
        //   this.variablesGlobales.ultimoFichaje.isTareaCerrada = false
        //   console.log("fichaje con entrada " , this.variablesGlobales.ultimoFichaje);

        // }
        // if(this.variablesGlobales?.ultimoFichajeAM[0]?.Salida?.Fecha)  {
        //   this.variablesGlobales.ultimoFichaje = next[0].Salida
        //   this.variablesGlobales.ultimoFichaje.isTareaCerrada = true
        //   console.log("fichaje con salida ", this.variablesGlobales.ultimoFichaje)
        // }

        //this.variables.duracionTareaActual = moment(this.variables.ultimoFichaje.Fecha).diff(moment(), 'days') * -1 + " días"  + moment(this.variables.ultimoFichaje.Fecha).diff(moment(), 'hours') * -1 + " horas ";
 
        this.variables.duracionTareaActual = moment.duration(moment().diff(this.variables?.ultimoFichaje?.Fecha, 'days',true) * -1, "days").locale('es-ES').humanize(true);  // a day

        console.log("ultimo fichaje PROCESOS-GLOBALES", this.variables.ultimoFichajeAM)
      },
      error => {
        console.error(" ultimo fichaje chungo", error);
      })
  }



}


