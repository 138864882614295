import { HttpHeaders } from '@angular/common/http';
import { VariablesService } from '../Servicios/variables.service';

export const globalConf = {

  user: "",
  pwd: "",

  GUID_Empresa: "7D53D629-D358-4B15-BA10-F6F38F613DEC",

  GUID_Registro: "401E8335-5853-4B9A-B462-7911FF29E686",
  CodigoEmpleado: "62",
  user_empresa: "rest_presencia@amsystem.es",
  pwd_empresa: "AMSYSTEM",

  url_api: "https://servicios.alosuite.com/api",
  //url_api : "https://servicios-beta.alosuite.com/api",
  //"http://localhost:59094/api",

  path_Carga_Usuarios: "/Administracion/CargaUsuarios/ID_Empresa ",
  path_Login_listadoEmpresas: "/Login",
  path_Listado_fichajes: "/Fichajes/PorFechas/",
  path_Ultimo_Fichaje: "/Fichajes/UltimoAFecha/",
  path_Personal: "/Personal/",
  path_Personal_empleados: "/Personal/Empleados/",
  path_Personal_Superiores: "/Personal/Superiores/",
  path_Personal_Departmentos_Responsable: "/Personal/DepartamentosResponsable/",
  path_Personal_Empleados_Dependencia: "/Personal/EmpleadosDependencia/",
  path_administracion_empresa: "/Administracion/EmpresaWeb/",
  path_administracion_usuario: "/Administracion/UsuarioWeb/",
  path_administracion_version: "/Administracion/VersionApp/",
  path_account_resetPassword: "/Account/ResetPassword",
  path_account_confirmReset: "/Account/ConfirmReset",
  path_jornada_laboral: "/jornadas/JornadaEmpleado/",
  path_solicitudesEmpleado: "/Solicitudes/SolicitudesEmpleado/",
  path_solicitudes: "/Solicitudes/",
  path_denegar_solicitud: "/Solicitudes/DenegarSolicitud/",
  path_aprobar_solicitud: "/Solicitudes/AprobarSolicitud/",
  path_produccion_activa: "/Administracion/ProduccionActiva/",
  path_incidencias_produccion: "/incidencias/Produccion/",
  path_test_empresas : "/Administracion/TestEmpresa/",
  path_parametros_ampresencia : "/Parametros/",
  path_acumulados_totales_porfechas : '/Acumulados/TotalesPorFechas/',
  path_acumulados_porfechas : '/Acumulados/PorFechas/',
  path_parametros_presencia : '/Parametros/Presencia/',
  path_v2 : '/v2/erp/',
  path_v2_crm : '/v2/crm/',
  path_v2_minimo : '/v2/erp/minimo/',
  path_v2_basico : '/v2/erp/basico/',
  path_proyectosv2 : '/proyectos',
  path_obrasv2 : '/obras',
  path_tickets : '/tickets',
  path_estados_tickets : '/estadosticket',
  
  path_tareas : '/tareas',
  path_eventos : '/eventos',
  path_clientes : '/clientes',
  path_ResetPasword:"api/Account/ResetPassword",
  path_Parametros:"api/Parametros/Documentos/",
  path_ParametrosEmpresa:"api/alo/empresas/",
  path_EstadosTareas : "/v2/crm/estadostarea",

  path_cambiosEstadosTareas : "/tareas/estado",
  path_cambiosEstadosTickets : "/TicketHistorialEstado",
  



  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  //autorization              : "Basic cmVzdF9wcmVzZW5jaWFAYW1zeXN0ZW0uZXM6QU1TWVNURU0=",
  authorization: 'Basic ZGNvcnRlc0BhbXN5c3RlbS5lczpwZG0xOTEy',

  app_version: '3.3.1',

  url_google_maps_api_geocode: 'https://maps.googleapis.com/maps/api/geocode/json',
  api_key_google_browser: 'AIzaSyAwIyX9pL25zOPNOV52gG76SbTAEz6sbKg',
  api_key_google_android: 'AIzaSyCPdG5rBrUREEn6am-OEV9ihBaGykOsccI',
  api_key_google_ios: 'AIzaSyDXKmeMCIno0IsDfTQfBnY9ANcIZm8CIYE',
  api_key_google_server: 'AIzaSyDPPISZ9oNuVZ7BvtC2g7fswPLfodHh0zE',


  appPagesPrincipal: [

    {
      title: 'Login',
      url: '/home',
      icon: 'log-in',
    },
    {
      title: 'Configuración',
      url: '/configuracion',
      icon: 'settings'
    },
    {
      title: 'Altas',
      url: '/altas',
      icon: 'person-add'
    },

  ],

  appPagesPerfil00: [

    /* {
      title: 'Login',
      url:   '/home',
      icon:  'log-in',
    }, */
    {
      title: 'Fichar',
      url: '/fichar',
      icon: 'finger-print',
    },
    {
      title: 'Fichajes',
      url: '/calendario',
      icon: 'journal',
    },
    {
      title: 'Jornadas',
      url: '/calendario-laboral',
      icon: 'calendar',
    },
    {
      title: 'Emplead@',
      url: '/datosempleado',
      icon: 'person',
    },
    {
      title: 'Solicitudes',
      url: '/solicitudes',
      icon: 'document-text'
    },
    {
      title: 'Configuración',
      url: '/configuracion',
      icon: 'settings'
    },
    {
      title: 'Cerrar sesión',
      url: '/home',
      icon: 'log-out',
    }
  ],

  appPagesPerfil50: [

    /* {
      title: 'Login',
      url:   '/home',
      icon:  'log-in',
    }, */
    {
      title: 'Fichar',
      url: '/fichar',
      icon: 'finger-print',
    },
    {
      title: 'Fichajes',
      url: '/calendario',
      icon: 'journal',
    },
    {
      title: 'Fichaje colectivo',
      url: '/pasar-lista',
      icon: 'clipboard',
    },
    {
      title: 'Jornadas',
      url: '/calendario-laboral',
      icon: 'calendar',
    },
    {
      title: 'Emplead@s',
      url: '/datosempleado',
      icon: 'people',
    },
    {
      title: 'Solicitudes',
      url: '/solicitudes',
      icon: 'document-text'
    },
    {
      title: 'Configuración',
      url: '/configuracion',
      icon: 'settings'
    },
    {
      title: 'Cerrar sesión',
      url: '/home',
      icon: 'log-out',
    },
    /* {
      title : 'Panel',
      url: '/panel-control',
      icon: 'settings',
    } */
  ],

  appPagesPerfil99: [
    /* {
      title: 'Login',
      url:   '/home',
      icon:  'log-in',
    }, */
    {
      title: 'Fichaje colectivo',
      url: '/pasar-lista',
      icon: 'clipboard',
    },
    {
      title: 'Fichajes',
      url: '/calendario',
      icon: 'journal',
    },
    {
      title: 'Jornadas',
      url: '/calendario-laboral',
      icon: 'calendar',
    },
    {
      title: 'Emplead@s',
      url: '/datosempleado',
      icon: 'people',
    },
    {
      title: 'Solicitudes',
      url: '/solicitudes',
      icon: 'document-text'
    },
    {
      title: 'Configuración',
      url: '/configuracion',
      icon: 'settings'
    },
    {
      title: 'Cerrar sesión',
      url: '/home',
      icon: 'log-out',
    }
  ],

  festivosTipo1: [
    { d: 'w6', background: '#70b8e9' },
    { d: 'w7', background: '#70b8e9' },
    { d: '1/1', background: '#a72727' },
    { d: '1/6', background: '#a72727' },
    { d: '2/28', background: '#a72727' },
    { d: '5/1', background: '#a72727' },
    { d: '8/15', background: '#a72727' },
    { d: '10/12', background: '#a72727' },
    { d: '11/2', background: '#a72727' },
    { d: '12/6', background: '#a72727' },
    { d: '12/8', background: '#a72727' },
    { d: '12/25', background: '#a72727' },
    
    { d: new Date(2019, 11, 7), background: '#a72727' },

    { d: new Date(2019, 3, 18), background: '#f19e20' },
    { d: new Date(2019, 3, 19), background: '#f19e20' },

    { d: new Date(2020, 3, 9), background: '#f19e20' },
    { d: new Date(2020, 3, 10), background: '#f19e20' },

    { d: new Date(2021, 3, 1), background: '#f19e20' },
    { d: new Date(2021, 3, 2), background: '#f19e20' },

    { d: new Date(2022, 3, 14), background: '#f19e20' },
    { d: new Date(2022, 3, 15), background: '#f19e20' },

    { d: new Date(2023, 3, 6), background: '#f19e20' },
    { d: new Date(2023, 3, 7), background: '#f19e20' },

    { d: new Date(2024, 2, 28), background: '#f19e20' },
    { d: new Date(2024, 2, 29), background: '#f19e20' },

    { d: new Date(2025, 3, 17), background: '#f19e20' },
    { d: new Date(2025, 3, 18), background: '#f19e20' },

    { d: new Date(2026, 3, 2), background: '#f19e20' },
    { d: new Date(2026, 3, 3), background: '#f19e20' },

    { d: new Date(2027, 2, 25), background: '#f19e20' },
    { d: new Date(2027, 2, 26), background: '#f19e20' },

    { d: new Date(2028, 3, 13), background: '#f19e20' },
    { d: new Date(2028, 3, 14), background: '#f19e20' },

    { d: new Date(2029, 2, 29), background: '#f19e20' },
    { d: new Date(2029, 2, 30), background: '#f19e20' },
  ],
}