import { Injectable, OnInit } from '@angular/core';
import { EmpresasUsuario } from '../Modelos/empresas_usuario';
import { Departamento } from '../Modelos/departamento';
import { Empleado } from '../Modelos/empleado';
import { IncidenciasProduccion } from '../Modelos/incidenciasProduccion';
import { MotivosFichajesProduccion } from '../Modelos/motivosFichajesProduccion';
import { Fichaje } from '../Modelos/fichaje';
import { FichajeProduccion } from '../Modelos/fichajeProduccion';
import { Ticket } from '../tab2/lista-tikets/model/Ticket';
import { Proyecto } from '../tab2/lista-proyectos/model/Proyecto';
import { Obra } from '../tab2/lista-obras/model/Obra';
import { ClienteMinimo } from '../tab2/lista-clientes/model/ClienteMinimo';
import { Motivo } from '../tab2/lista-motivos/model/motivo';
import { FichjesServicioAMPresencia } from '../Modelos/fichajesAMpresenciaServicio';
import { Tarea } from '../tab2/lista-tikets/model/Tarea';
import { EstadoTarea } from 'c:/Users/AM Desarrollo 5/Proyectos/AloPresenciaTactil/src/app/tab2/lista-estadosTareas/model/estadotarea';
import { EstadoTicket } from '../tab2/lista-tikets/model/estado';

@Injectable({
    providedIn: 'root'
  })
  export class VariablesService  {

    public loginCorrecto: boolean;
    public usuario: string;
    public pwd: string;
    public autorizacionBase64 : string;  
    public empresasUsuario : EmpresasUsuario[];
    public empresaSeleccionada : EmpresasUsuario;
    public departamentosUsuario : Departamento[];
    public departamentoSeleccionado : Departamento;
    public empleadosDepartamento : Empleado[];
    public empleadoSeleccionado : Empleado;
    public motivosFichejeProduccion : MotivosFichajesProduccion[];
    public motivoFichajeSeleccionado : MotivosFichajesProduccion;
    public ultimoFichaje : Fichaje;
    public cierrePendiente : FichajeProduccion;
    public recordarUsuario: boolean;
    public motivoFichaje: string;
    public itemsPorColumna: number;
    public tieneNotasProduccion: boolean;
    public proyectos: Proyecto[];
    public proyectoSeleccionado: Proyecto;
    public obras: Obra[];
    public obraSeleccionada: Obra;
    public tickets: Ticket[];
    public ticketSeleccionado: Ticket;
    public clientes : ClienteMinimo[];
    public clienteSeleccionado: ClienteMinimo;
    public ocultarProyectos: boolean;
    public ocultarTickets: boolean;
    public ocultarObras: boolean;
    public ocultarClientes: boolean;
    public proyectoSelecionadoTieneCliente: boolean;
    public obraSelecionadaTieneCliente: boolean;
    public ticketSelecionadoTieneCliente: boolean;
    public actualizadoClientes: boolean;
    public claveEncriptacion: string = "AutoVenta";
    public cargadosClientes: boolean;
    public cargadasObras: boolean;
    public cargadosTickets: boolean;
    public cargadosTodosTickets : boolean;
    public cargadosProyectos: boolean;
    public actualizadoTickets: boolean;
    public leyendo: boolean;
    public seleccionarMios: boolean;
    public ultimoFichajeAM: FichjesServicioAMPresencia[];
    public actualizadoMotivo: boolean;
    public motivos: Motivo[];
    public ocultarMotivos: boolean;
    public motivoSeleccionado: Motivo;
    public cargadosMotivos: boolean;
    public departamentoConTickets: boolean;
    public tecnicoConTickets: boolean;
    public fichajeAutorizado: boolean;
    public tareas: Tarea[];
    public actualizadoTareas: boolean;
    public cargadasTareas: boolean;
    public ocultarTareas: boolean;
    public tareaSeleccionada: Tarea;
    public todosTickets: Ticket[];
    public actualizadoTodosTickets: boolean;

    public estadosTareas: EstadoTarea[];
    public estadoTareaSeleccionado: EstadoTarea;
    public ocultarEstadosTareas: boolean;

    public estadosTickets: EstadoTicket[];
    public estadoTicketSeleccionado: EstadoTicket;
    public ocultarEstadosTickets: boolean;
    public actualizadoEventos: boolean;
    public eventos: Tarea[];
    public eventoSeleccionado: Tarea;
  cargadosEventos: boolean;
  fechaUltimoFichaje: string;
  duracionTareaActual: string;
   
   

    constructor() {
      this.loginCorrecto = false;
      this.usuario = "";
      this.pwd = "";
      this.autorizacionBase64 = "";
      this.empresasUsuario = [];
      this.empresaSeleccionada = null
      this.departamentosUsuario = [];
      this.departamentoSeleccionado = null;
      this.empleadosDepartamento = [];
      this.empleadoSeleccionado = null;
      this.motivosFichejeProduccion = [];
      this.motivoFichajeSeleccionado = null;
      this.ultimoFichaje = null;
      this.cierrePendiente = null;
      this.recordarUsuario = null;
      this.motivoFichaje = null;
      this.itemsPorColumna = null;
      this.tieneNotasProduccion = false;
      this.proyectos = []
      this.proyectoSeleccionado = null;
      this.obras = []
      this.obraSeleccionada = null;
      this.tickets = [];
      this.ticketSeleccionado = null;
      this.clientes = [];
      this.clienteSeleccionado = null;
      this.ocultarProyectos = false;
      this.ocultarTickets = false;
      this.ocultarObras = false
      this.ocultarClientes = false;
      this.proyectoSelecionadoTieneCliente = false;
      this.obraSelecionadaTieneCliente = false ;
      this.ticketSelecionadoTieneCliente = false;
      this.cargadasObras = false;
      this.cargadosClientes = false;
      this.cargadosProyectos = false;
      this.cargadosTickets = false;
      this.leyendo = false;
      this.seleccionarMios = false;
      this.tecnicoConTickets = false;
      this.departamentoConTickets = false;
      this.ultimoFichajeAM = null;
      this.actualizadoMotivo = false;
      this.motivos= [];
      this.ocultarMotivos = false;
      this.motivoSeleccionado = null;
      this.cargadosMotivos = false;
      this.tareas = [];
      this.actualizadoTareas= false;
      this.cargadasTareas= false;
      this.ocultarTareas = false;
      this.tareaSeleccionada = null;
      this.todosTickets = [];
      this.actualizadoTodosTickets = false;



      this.estadosTareas = [];
      this.estadoTareaSeleccionado = null;
      this.ocultarEstadosTareas =  false;
  
      this.estadosTickets =  [];
      this.estadoTicketSeleccionado = null;
      this.ocultarEstadosTickets = false;

      this.actualizadoEventos = false;
      this.eventos = [];
      this.eventoSeleccionado = null;

     

    }

  }