import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { VariablesService } from './variables.service';
import { globalConf } from '../Modelos/GlobalConf';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ParametrosPresencia } from '../Modelos/parametrosPresencia';
import { Empleado } from '../Modelos/empleado';
import { RespuestaV2 } from '../Modelos/respuestaV2';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(
    private loadingCtrl: LoadingController,
    private alertController: AlertController,

    private variables: VariablesService,
    public http: HttpClient

  ) { }


  async presentLoading(mensaje?: string) {

    if (mensaje != null) {
      let loading = await this.loadingCtrl.create({
        message: mensaje
      })
      await loading.present();

    } else {
      let loading = await this.loadingCtrl.create()
      await loading.present();
    }

  }


  async dissmisLoading() {
    this.loadingCtrl.dismiss()
  }

  async presentAlert(hearder: string, subhearder: string, message?: string, buttons?: string) {

    if (buttons == null) buttons = "De acuerdo";
    const alert = await this.alertController.create({
      header: hearder,
      subHeader: subhearder,
      message: message,
      buttons: [buttons]
    });

    await alert.present();
  }

  async presentAlertConfirm(hearder: string, subhearder: string, message: string) {
    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      header: hearder,
      subHeader: subhearder,

      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmar',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  obtenerLoginMASListaEmpresas(usuario, pwd): Observable<any> {
    console.log('En el servicio login');
    let body;
    body = { usuario: usuario, pwd: pwd };
    console.log('En el servicio login', body);
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('amsystemapp', '20');
    console.log('cabecera ' + cabecera.get('Content-Type'));
    this.variables.autorizacionBase64 = btoa(usuario + ':' + pwd);

    return this.http.post(
      globalConf.url_api + globalConf.path_Login_listadoEmpresas,
      body,
      { headers: cabecera }
    );
  }

  obtenerInfoEmpleado(): Observable<Empleado> {
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);


    return this.http
      .get(globalConf.url_api +
        globalConf.path_Personal + this.variables.empresaSeleccionada.GUID_Registro + "/2", { headers: cabecera }) as Observable<Empleado>;

  }

  obtenerDepartmentosResponsables() {
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api +
        globalConf.path_Personal_Departmentos_Responsable + this.variables.empresaSeleccionada.GUID_Registro, { headers: cabecera });
  }

  obtenerEmpleadosDependencia(departamento: number) {
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    return this.http
      .get(globalConf.url_api +
        globalConf.path_Personal_Empleados_Dependencia + this.variables.empresaSeleccionada.GUID_Registro + '/' + departamento, { headers: cabecera });

  }

  produccionActiva(): Observable<boolean> {
    console.group('servicioPresencia.produccionActiva()');
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);
    console.groupEnd();

    return this.http.get(
      globalConf.url_api +
      globalConf.path_produccion_activa +
      this.variables.empresaSeleccionada.GUID_Registro,
      { headers: cabecera }
    ) as Observable<boolean>;
    // https://servicio.ampresencia.com/api/Administracion/ProduccionActiva/8C935605-0870-45E4-AF81-1042C6DBAAF1
  }

  obtenerListaIncidenciasProduccion(cadena: string) {
    console.group('servicioPresencia.produccionActiva()');
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);
    console.groupEnd();

    return this.http.get(
      globalConf.url_api +
      globalConf.path_incidencias_produccion +
      this.variables.empresaSeleccionada.GUID_Registro +
      '/' + cadena, { headers: cabecera }
    );

    // https://servicio.ampresencia.com/api/incidencias/Produccion/8C935605-0870-45E4-AF81-1042C6DBAAF1/
  }

  ficharProduccion(
    fecha: string,
    entradaOsalida: boolean,
    codigoPersonal: number,
    observaciones: string,
    incidencia: number,
    produccion_referenciaOrden: string,
    produccion_Unidad1: string,
    notas?: string,
    id_Proyecto?: number,
    id_Obra?: number,
    id_Ticket?: number,
    id_Cliente?: number,
    id_Tarea? : number
  ): Observable<any> {


    let body;


    body = {
      codigoPersonal: codigoPersonal,
      fecha: fecha,
      entrada: entradaOsalida,
      observaciones: observaciones,
      incidencia: incidencia,
      produccion_referenciaOrden,
      produccion_Unidad1,
      produccion: true,
      notas: notas,
      id_Proyecto: id_Proyecto,
      id_Obra: id_Obra,
      id_Ticket: id_Ticket,
      id_Cliente: id_Cliente,
      id_TareaTicket : id_Tarea
    };


    const cabecera = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64)
      .set('Content-Type', 'application/json');

    return this.http.post(globalConf.url_api +
      '/Fichajes/' +
      this.variables.empresaSeleccionada.GUID_Registro, body, { headers: cabecera });
  }

  obtenerFichajesempleados(
    fechaInicial: string,
    fechaFinal: string,
    codigoempleado: string
  ) {
    console.log('Obtener fichajes empleados de administrador ');
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);
    const parametros = new HttpParams()
      .set('FechaInicial', fechaInicial)
      .set('FechaFinal', fechaFinal)
      .set('CodigoEmpleado', codigoempleado);

    return this.http.get(
      globalConf.url_api +
      globalConf.path_Listado_fichajes +
      this.variables.empresaSeleccionada.GUID_Registro,
      { headers: cabecera, params: parametros }
    );
  }

  obtenerUltimoFichaje(fechaFinal: string, codigoempleado: number, produccion: boolean , ultimaEntrada : boolean) {
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    const parametros = new HttpParams()
      .set('FechaFinal', fechaFinal)
      .set('Produccion', produccion.toString())
      .set('CodigoEmpleado', codigoempleado?.toString())
      .set('DosUltimosfichajes' , ultimaEntrada.toString())

    let body = { CodigoEmpleado: codigoempleado?.toString() }

    return this.http.get(
      globalConf.url_api +
      globalConf.path_Ultimo_Fichaje +
      this.variables.empresaSeleccionada.GUID_Registro, { headers: cabecera, observe: "body", params: parametros }
    )



  }
  

  obtenerParametrosPresencia() {
    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);


    return this.http.get<ParametrosPresencia[]>(globalConf.url_api + globalConf.path_parametros_presencia + this.variables.empresaSeleccionada.GUID_Registro, { headers: cabecera })
  }

  getTicketsDepartamentos(): Observable<RespuestaV2> {
    console.log("tickets departamento empleadoseleccionado", this.variables.empleadoSeleccionado )
    console.log("tickets departamento departametoseleccionado", this.variables.departamentoSeleccionado )

    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    const parametros = new HttpParams()
      .set('idsDepartamento', this.variables.empleadoSeleccionado?.Id_Dependencia)
      .set('idsEstadoTicket', '20060124,20060125,21151910,21151911')
    console.log("departamentos ", this.variables?.departamentoSeleccionado?.NombreDependencia)

    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tickets, { headers: cabecera, params: parametros }) as Observable<RespuestaV2>
      ;
  }



  getTicketsTecnicos(): Observable<RespuestaV2> {
    console.log("tickets departamento empleadoseleccionado", this.variables.empleadoSeleccionado )
    console.log("tickets departamento departametoseleccionado", this.variables.departamentoSeleccionado )

    const cabecera = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.variables.autorizacionBase64);

    const parametros = new HttpParams()
      .set('idsEstadoTicket', '20060124,20060125,21151910,21151911')
      .set('idsTecnicos', this.variables.empresaSeleccionada.Id_Empleado)
    console.log("tecnico ", this.variables?.empleadoSeleccionado?.Nombre)

    return this.http
      .get(globalConf.url_api +
        globalConf.path_v2_crm + this.variables.empresaSeleccionada.GUID_Registro + globalConf.path_tickets, { headers: cabecera, params: parametros }) as Observable<RespuestaV2>
      ;
  }
}
