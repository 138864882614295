import { Component } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen, SplashScreenOriginal } from '@ionic-native/splash-screen';
import { StatusBar, StatusBarOriginal } from '@ionic-native/status-bar';

// ACTUALIZAR PWA
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Component
({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    
   // private splashScreen: SplashScreenOriginal,
   // private statusBar: StatusBarOriginal
       // ACTUALIZAR PWA
       public swUpdate: SwUpdate,
       public toastController: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
      this.actualizarVersion();
    });
  }

  actualizarVersion() {
    if (!this.swUpdate.isEnabled) {
      console.log("SwUpdate not enbaled");
      return
    } else {
      console.log("SwUpdate enbaled");
    }
    this.swUpdate.checkForUpdate().then(() => console.log('Comprobada version PWA al abrir aplicacion'));
    this.swUpdate.available.subscribe(async (event) => {
      console.log('current: ', event.current, 'available: ', event.available)
      const toast = await this.toastController.create({
        header: 'Actualización disponible',
        message: 'Hay una nueva versión de ALO Control de producción.',
        color:'light',
        position: 'bottom',
        buttons: [
          {
            text: 'Actualizar',
            handler: () => {
              this.swUpdate.activateUpdate().then(() => location.reload())
            }
          }
        ]
      });
      await toast.present();

      // if(confirm('Hay una nueva versión disponible. Acepte para actualizar')){
      //   this.swUpdate.activateUpdate().then(()=> location.reload())
      // }
    })
    // this.swUpdate.activated.subscribe((event)=>{
    //   console.log('current: ',event.previous, 'available: ',event.current)
    // })


  }

  comprobarActualizacion() {
    // COMPROBACIÓN DE LA VERSIÓN PARA LA PRIMERA VEZ
    this.swUpdate.checkForUpdate().then(() => {
      console.log("Checked");
    })

    // INTERVALOS DE COMPROBACIÓN
    const timeInterval = interval(60000);
    timeInterval.subscribe(() => {
      this.swUpdate.checkForUpdate().then(() => {
        console.log("Checked");
      })
    })
  }
}


