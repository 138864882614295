// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-page {
  display: grid !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../../../AM%20Desarrollo%205/Proyectos/amsystem-controlproduccion/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;ACCJ","sourcesContent":[".ion-page {\r\n    display: grid !important;\r\n}",".ion-page {\n  display: grid !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
