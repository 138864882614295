// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redondel {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4679bd;
  text-align: center;
  padding-top: 8px;
}

.diaGrandeAgenda {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #f6f7f9;
}`, "",{"version":3,"sources":["webpack://./src/app/login/empresas-usuario/empresas-usuario.component.scss","webpack://./../../../AM%20Desarrollo%205/Proyectos/amsystem-controlproduccion/src/app/login/empresas-usuario/empresas-usuario.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;ACCJ;;ADGE;EACE,wEAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;ACAJ","sourcesContent":[".redondel {\r\n    width: 100%;\r\n    height: 100%;\r\n    border-radius: 50%;\r\n    background-color: #4679bd;\r\n    text-align: center;\r\n    padding-top: 8px;\r\n    //box-shadow: -2px 2px 5px 0px #123158;\r\n  }\r\n  \r\n  .diaGrandeAgenda {\r\n    font-family: \"Franklin Gothic Medium\", \"Arial Narrow\", Arial, sans-serif;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    color: #f6f7f9;\r\n    //text-shadow: -2px 2px 5px 1px black;\r\n    //text-shadow: #627ca0\r\n  }",".redondel {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #4679bd;\n  text-align: center;\n  padding-top: 8px;\n}\n\n.diaGrandeAgenda {\n  font-family: \"Franklin Gothic Medium\", \"Arial Narrow\", Arial, sans-serif;\n  font-size: 20px;\n  font-weight: bold;\n  color: #f6f7f9;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
