export class FichajeProduccion {
    fecha: string;
    entradaOsalida: boolean;
    codigoPersonal : number;
    observaciones: string;
    incidencia: number;
    produccion_referenciaOrden: string;
    produccion_Unidad1: number;
    produccion : boolean;
    nota ? : string;
    idProyecto ? : number;
    idObra ? : number;
    idTicket ? : number;
    idCliente ? : number;
    idTarea ? : number;
}